.LanguagePicker {
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 40;
    cursor: pointer;

    .language {
        display: none;
        padding: 10px;
        transition: all .2s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }

        &.selected {
            display: block;
        }

        img {
            width: 30px;
            height: 20px;
        }
    }

    &.open {
        background-color: rgba(24, 24, 24, .70);

        .language {
            display: block;
        }
    }
}
