@import '../../../styles/mixins/general-mixins';

.CustomerContainer {
    width: 100%;
    display: table;

    .required {
        color: #bb1212;
        padding-left: 5px;
        font-size: 22px;
    }

    .valid {
        color: green;
        font-size: 18px;
    }

    .gdpr-text {
        font-size: 12px;
        padding: 0 20px 20px;
    }

    .textbox {
        margin: 5px 0;

        label {
            width: 120px;
            display: inline-block;
        }

        input {
            width: 55%;
            height: 30px;
        }

        .error,
        .helper-text {
            width: 65%;
            margin: 0 30px 0 120px;
        }

        .helper-text {
            font-size: 0.8em;
        }
    }

    .checkboxes {
        width: 75%;
        margin-left: 122px;
        margin-top: 15px;

        label {
            margin-right: 15px;
            display: inline-flex;
            align-items: center;
        }

        .conditions {
            margin-top: 10px;

            .error {
                margin-left: 3px;
            }
        }

        .gdpr-text {
            padding: 5px;
            padding-left: 0;
        }

        .parkingContainer {
            margin-left: -122px;
            margin-bottom: 30px;
            //border:1px solid;
            div {
                display: grid;
                grid-template-columns: 120px 1fr
            }

            .inputContainer {
                position: relative;
                left: 122px;
                width: 180px;
                height: 43px;
                border-radius: 4px;
                background-color: white;

                .input_border {
                    border: 1px solid #ea3323;
                    width: 176px;
                    height: 38px;
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    border-radius: 4px;

                    .logos {
                        width: 22px;
                        background-color: #29609C;
                        display: flex;
                        flex-direction: column;
                        height: 33px;
                        margin-top: 2px;
                        margin-left: 2px;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;

                        .input_dk {
                            margin-top: 4px;
                            margin-left:3px;
                        }
                    }

                    input {
                        width: 145px;
                        height: 34px;
                        position: absolute;
                        left: 27px;
                        background-color: white;
                        outline: none;
                        text-transform:uppercase;
                        font-size:22px;
                       
                    }
                }
            }
        }
    }
}


@media (max-width: 705px) {
    .CustomerContainer {
        .textbox input {
            width: 67%;
        }
    }
}


@media (max-width: 570px) {
    .CustomerContainer {
        .textbox {
            label {
                width: 95px;
            }
        }

        .checkboxes,
        .textbox .error,
        .textbox .helper-text {
            margin-left: 95px;
        }

        input[type=text],
        input[type=tel],
        input[type=email],
        input[type=password] {
            font-size: 16px;
        }
    }
}


@media (max-width: 400px) {
    .CustomerContainer {
        &:nth-last-child(1) {
            padding-bottom: 30px;
        }

        .textbox {
            width: 100%;

            .error {
                width: 100%;
                margin-left: 0;
            }

            input {
                width: 90%;
            }

            .helper-text {
                width: 100%;
                margin: 0;
            }
        }

        .checkboxes {
            width: 100%;
            margin-left: 0;
        }
    }
}
