﻿@mixin two-columns {
    .two-columns {
        width: 50%;
        float: left;
        overflow: hidden;
        position: relative;
        margin-bottom: -6px;
        background-image: linear-gradient(150deg,lightgray 0%,#eee 75%);

        &:hover > .Poster {
            box-shadow: 0 8px 15px -7px black;

            img {
                transform: scale(1.05);
            }
        }

        h1 {
            font-size: 1.7rem;
            padding: 20px 0 10px;
        }

        .Poster {
            width: 40%;
            float: left;
            overflow: hidden;

            &:hover {
                cursor: pointer;
            }

            img {
                width: 100%;
                height: auto;
                transition: all .2s ease-in-out;
            }
        }

        .movie-info {
            width: 60%;
            float: left;
            padding: 0 20px 20px;

            .MovieInfo {
                float: left;
                height: 185px;

                label {
                    width: 55%;
                }
            }

            .read-more {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 60%;
                text-align: right;
                margin: 0;
                padding: 30px 15px 20px;
                background-image: linear-gradient(to top, rgb(236, 236, 236) 25%,rgba(255,255,255,0) 100%);
                transition: all .2s ease-in-out;
            }

            .showtimes {
                width: 100%;
                display: inline-block;
                padding: 0;

                .showtime {
                    margin-left: 0;
                }
            }
        }

        .Showtime {
            width: 42%;
            float: left;
            margin: 4%;
        }

        .ShowtimeTypeList {
            width: 60%;
            position: absolute;
            bottom: 10px;
            right: 0;
        }

        &.first-in-row3,
        &.first-in-row4,
        &.first-in-row5 {
            clear: none;
        }

        &.first-in-row2 {
            clear: both;
        }
    }

    @media (max-width: 900px) {
        .two-columns {
            &.MovieListItem {
                .movie-info h1 {
                    padding-bottom: 10px;
                }
            }
        }
    }

    @media (max-width: 750px) {
        .two-columns {
            width: 100%;

            .movie-info .MovieInfo p label {
                width: 70%;
            }
        }
    }

    @media (max-width: 600px) {
        .two-columns {
            .movie-info .MovieInfo p label {
                width: 55%;
            }
        }
    }

    @media (max-width: 380px) {
        .two-columns {
            height: auto;

            .Poster,
            .movie-info {
                width: 100%;
                height: auto;
                float: left;
            }

            .movie-info {
                h1 {
                    font-size: 1.2rem;
                }

                .read-more {
                    width: 100%;
                }
            }
        }
    }
}
