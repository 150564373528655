@media print {
    @page {
        margin: 0;
    }

    body {
        width: 100%;
        height: 100%;
        padding: .5cm;
    }

    * {
        background-color: white;
        color: black;
    }

    nav,
    input[type=button] {
        display: none;
    }

    .TopBar {
        width: 100%;
        height: 250px;
        display: table;
        overflow: hidden;
        margin: auto;
        margin-bottom: 20px;

        .TicketInfo {
            color: black;
            padding: 0 2%;

            h1 {
                color: black;
                position: unset;
                min-height: 30px;
                padding-top: 2%;
                padding-left: 0;
            }

            h3 {
                color: black;
            }
        }
    }

    .LanguagePicker {
        display: none;
    }

    .ReceiptContainer {
        img, .receipt-info {
            float: left;
        }

        img {
            width: 160px;
            height: auto;
        }

        .receipt-info {
            width: 500px;
            padding-left: 20px;
            font-size: 17px;

            .organizer-info span,
            .customer-info span {
                font-weight: bold;
            }
        }
    }

    .Summary {

        .icon-chevrons-up,
        .icon-chevrons-down {
            display: none;
        }
    }
}
