﻿@mixin one-column {
    .one-column {
        width: 100%;
        margin-bottom: 20px;
        overflow: hidden;
        float: left;

        h1 {
            background: black;
            color: white;
            margin: 0;
            padding: 10px 20px;
            min-height: initial;
        }

        h3 {
            margin: 0;
            font-size: 1.35rem;
        }

        &:hover > .Poster {
            img {
                box-shadow: 0 8px 15px -7px black;
                transform: scale(1.05);
            }
        }

        .Poster {
            width: 20%;
            height: 100%;
            float: left;
            overflow: hidden;
            max-height: 342px;

            img {
                width: 100%;
                transition: all .2s ease-in-out;
            }

            .age-banner img {
                width: 30px;
            }
        }

        .movie-info {
            width: 80%;
            float: left;

            h1:hover {
                cursor: pointer;
            }

            .future-info,
            .organizer {
                background-image: linear-gradient(148deg, #f2f2f2 16%, #ebebeb 90%);
            }

            .date {
                margin-bottom: 10px;
                font-size: 19px;
                font-weight: bold;
            }

            .future-info {
                padding: 20px;
            }

            .showtimes {
                width: 100%;
                padding: 10px 20px 20px;
                display: flex;
                flex-direction: column;
                background-image: linear-gradient(148deg, #f2f2f2 16%, #ebebeb 90%);
            }

            .row {
                margin-bottom: 10px;
            }

            .showtime-row {
                margin: 5px 0;
                display: flex;
                align-items: center;
            }

            .location {
                white-space: nowrap;
            }

            .type {
                font-weight: bold;
            }

            .info {
                width: 100%;
                float: left;
                margin-top: 10px;
            }

            .read-more {
                width: 100%;
                background: white;
                padding: 20px;

                .slide-down {
                    padding-top: 17px;
                }

                &.future {
                    background: none;
                    float: none;
                    padding: 20px 0;
                    margin-bottom: 13px;

                    .next {
                        margin-right: 0;
                    }
                }

                .MovieInfo {
                    transition: all .1s linear;
                }

                .more-showtimes {
                    margin-right: 5px;
                    @include button-gray;

                    &:hover {
                        box-shadow: 0 8px 15px -7px black;
                        background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(156, 156, 156, 0.4));
                    }
                }
            }
        }
    }

    @media (max-width: 900px) {
        .one-column {
            .Poster {
                width: 25%;
            }

            .movie-info {
                width: 75%;
            }
        }
    }

    @media (max-width: 750px) {
        .one-column {

            .Poster {
                width: 30%;
            }

            .movie-info {
                width: 70%;
            }
        }
    }

    @media (max-width: 610px) {
        .one-column {
            .Poster {
                width: 20%;
            }

            .movie-info {
                width: 80%;
            }

            h1 {
                font-size: 1.2rem;
            }

            .info, .MovieInfo {
                font-size: 15px;
            }

            .MovieInfo p {
                display: flex;

                span {
                    flex-basis: 25%;
                }

                label {
                    flex-basis: 75%;
                }
            }
        }
    }

    @media (max-width: 420px) {
        .one-column {
            &.MovieListItem {
                padding-bottom: 15px;
            }

            .Poster img {
                width: 100%;
            }

            .movie-info .organizer {
                width: 100%;
            }

            .MovieInfo p {
                span {
                    flex-basis: 30%;
                }

                label {
                    flex-basis: 70%;
                }
            }
        }
    }
}
