@import '../../../styles/mixins/general-mixins';

.ClubCard {
    width: 100%;
    height: 43px;
    display: table;
    background: gray;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.05));
    color: white;

    h1 {
        display: inline-block;
        margin: 5px 10px 5px 20px;
    }

    .login span {
        float: right;
        margin: 13px 10px;
        font-size: 14px;
        vertical-align: middle;
    }

    .login-button {
        margin-top: 5px;
    }

    .Login {

        form {
            width: 355px;
            margin: auto;
        }

        .textbox {
            margin: 20px 0;

            label {
                width: 20%;
                min-width: 70px;
                display: inline-block;
                text-align: left;
            }

            input[type=text],
            input[type=password] {
                width: 73%;
                margin-left: 10px;
            }
        }

        .reset-password {
            text-align: right;
            margin: -10px 7px 20px;
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
        }

        .buttons {
            text-align: right;
            margin-right: 8px;

            .error {
                font-size: 14px;
                margin-left: 8px;
                margin-bottom: 20px;
                color: #ff3f3f;
                text-align: center;
                padding: 10px;
                background: #353535;
                border: 1px solid #313131;
                border-radius: 3px;
            }

            .red {
                min-width: 80px;
            }
        }
    }

    h3 {
        float: right;
        margin: 11px 20px 5px;
    }
}

@media (max-width: 620px) {
    .ClubCard {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
       // z-index: 20;
    }
}

@media (max-width: 500px) {
    .ClubCard {
        h1 {
            margin-top: 5px;
        }

        .login span {
            font-size: 12px;
        }
    }
}

@media (max-width: 390px) {
    .ClubCard {
        height: 34px;

        .login span {
            display: none;
        }
    }
}

@media (max-width: 355px) {
    .ClubCard {
        .Login form {
            width: 100%;

            label {
                font-size: 15px;
                width: 50px;
            }

            .reset-password {
                margin-right: 20px;
            }
        }
    }
}

@media (max-height: 345px) {
    .ClubCard .Login {
        bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}