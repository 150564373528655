@import '../../../styles/mixins/general-mixins';

.PaymentContainer {
    width: 100%;
    display: table;

    .required {
        color: #bb1212;
        padding-left: 5px;
        font-size: 22px;
    }

    .valid {
        color: green;
        font-size: 18px;
    }

    .giftcard {
        padding: 0 20px;
        margin-bottom: 15px;
        @include button-green;
    }

    //TODO remove this when we migrate away from dibs
    .Payment {
        width: 50%;
        float: left;

        .CreditCardOption {
            height: 33px;
            display: block;
            background-color: #eee;
            padding: 5px;
            margin: 5px 0;
            cursor: pointer;

            input {
                vertical-align: middle;
                margin-bottom: 3px;
            }

            img {
                width: 30px;
                height: 20px;
                margin: 0 10px 2px;
                vertical-align: middle;
            }

            span {
                display: inline-block;
                margin-top: 1px;
            }
        }
    }

    .DibsForm {
        width: 100%;
        margin: auto;

        #dibsiframe {
            width: 100%;
        }
    }
}


@media (max-width: 705px) {
    .PaymentContainer {
        .Payment {
            .error {
                text-align: left;
            }
        }
    }
}

@media (max-width: 600px) {
    .PaymentContainer {
        .Payment {
            width: 100%;
        }
    }
}
