@import '../../../styles/mixins/general-mixins';

// setting the backdrop to blur when alta pays creditcard pops up to complete the transaction
.PensioEmbeddedPaymentWindowBackground {
    backdrop-filter:blur(5px);
    }

.AltaPaymentContainer {
    background-color: #2c2c2c;

    .content-container {
        display: grid;
        /*border: 1px solid;*/
        grid-template-columns: 1fr 1fr;
        padding: 0;
        background-color: #fff;

        .altaPayMulti {
            //border: 1px solid;
            padding: 20px 50px;

            .title {
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0.3px;
                color: #1e1e1e;
            }

            .desc {
                color: #1e1e1e;
                letter-spacing: 0.32px;
                font-size: 16px;
            }

            form {
                border-top: 1px solid #c3c3c3;
                color: #1e1e1e;
                font-size: 13px;
                margin-top:30px;
                .order {
                    padding: 0px 30px;
                }

                .extra {
                    border-bottom: 1px solid #c3c3c3;
                    height:35px;
                }


                .sum_container {
                    border-bottom: #414141;
                    display: flex;
                    justify-content: space-between;
                    font-size: 13px;
                    color: #1e1e1e;
                    font-weight: 800;
                    letter-spacing: 0.14px;
                    border-bottom: 1px solid #414141;
                    height: 35px;
                }
            }
        }

        .alta_giftcardContainer {
            margin-left: 50px;
            margin-top: 32px;
            margin-right: 50px;





            .giftcard_title {
                color: #1e1e1e;
                font-weight: 600;
                letter-spacing: 0.32px;
                height: 10px;
            }



            input[type='submit'] {
                width: 133px;
                background-image: linear-gradient(257deg, #3f3f3f, #000 -46%);
            }

            .input-info {
                font-size: 11px;
                letter-spacing: 0.11px;
                color: #1e1e1e;
            }

            .giftcardContainer_title {
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0.3px;
                color: #1e1e1e;
                margin: 0;
            }

            .movie_desc {
                color: #1e1e1e;
                letter-spacing: 0.32px;
                font-size: 16px;
            }

            .alertinfo {
                margin-top: 12px;
                height: 22px;
                //color: white;
                //background-color: #9c0301;
                font-size: 11px;
                font-weight: 600;
                padding-left: 8px;
                line-height: 18px;
                border-radius: 2px;
            }

            .ticketsInfos {
                margin-top: 30px;
                border-top: 1px solid #c3c3c3;
                border-bottom: 1px solid #c3c3c3;

                .active {
                    p {
                        width: 100%;
                    }
                }

                .ticketQnt {
                    height: 23px;
                    color: #1e1e1e;
                }




                .giftcarsnumber_cont {
                    display: flex;
                    justify-content: space-between
                }

                div {
                    display: flex;
                    justify-content: space-between;


                    p {
                        font-size: 13px;
                        line-height: 10px;
                    }
                }
            }

            .sum_container {
                border-bottom: #414141;
                display: flex;
                justify-content: space-between;
                font-size: 13px;
                color: #1e1e1e;
                font-weight: 800;
                letter-spacing: 0.14px;
                border-bottom: 1px solid #414141;
                height: 35px;
                /*margin-top: -5px;*/
            }
        }

        .altaContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .alta_altaContainer {
                background-color: #efefef;
                padding-left: 50px;
                padding-top: 50px;
                padding-right: 50px;
                height: 100%;



                .paymethod_text {
                    font-weight: 800;
                }

                #payment-methods {
                    width: 100% !important;
                    display: grid !important;
                    font-size: 13px;

                    /*.paying_by {
                        position: absolute;
                        left: 50px;
                    }*/
                    /*input[type='radio'] {
                        position: absolute;
                        left: 20px;
                        //top:15px;
                    }*/
                }

                .payment-method-logo {
                    padding-top: 10px;
                }

                .paymentMethodContainer {
                    background-color: white;
                    width: 100%;
                    border: solid 1px #e8e8e8;




                    .payment-item-container {
                        position: relative;
                        border-bottom: 1px solid#e8e8e8 !important;
                        display: flex;
                        padding: 10px 20px;

                        .payment-item {
                            display: flex;
                            justify-content: space-between;
                            margin-left: 20px;
                           width: 100%;
                            flex-direction: row-reverse;

                            .payment-description {
                                margin-top: 6px;
                            }
                        }
                    }

                    .payment-item-container input[type='radio'] {
                        appearance: none;
                        background-clip: content-box;
                        background-color: transparent;
                        border: 1px solid dimgrey;
                        border-radius: 50%;
                        display: inline-block;
                        height: 15px;
                        padding: 3px;
                        width: 15px;
                        margin-top: 6px;
                    }

                    .payment-item-container input[type='radio']:checked {
                        background-color: black;
                        border: 1px solid black;
                    }

                    img {
                        height: 30px;
                        width: 30px;
                    }
                    /*.PaymentCards_container {
                        display: flex !important;
                        justify-content: space-between !important;
                        flex-direction: row-reverse;
                        border-bottom: 1px solid#e8e8e8 !important;
                        padding-left: 20px;
                        padding-right:40px;
                        position:relative;

                        .payment-method-logo {
                            //opacity:unset !important;
                        }
                    }*/
                }
            }

            .theLastPriceCon {
                height: 83px;
                background-color: #efefef40;
                display: flex;
                justify-content: space-between;
                padding-left: 75px;
                padding-right: 81px;

                p {
                    color: #1e1e1e;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: 0.2px
                }
            }
        }
    }

    .btncontent-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .back_button {
        border-radius: 2px;
        background-image: linear-gradient(to right, #848484, #969796) !important;
        width: 102px;
        height: 43px;
        color: white;
    }

    .payment-button {
        height: 43px;
        color: white;
        font-size: 16px;
        font-weight: bold;
        background-image: linear-gradient(266deg, #950200, #9e0200 -50%)
    }

    #payment-button {
        padding: 9px 0 10px;
        height: 43px;
        color: white;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        border-radius: 2px;
        background-image: linear-gradient(266deg, #950200, #9e0200 -50%)
    }
}



@media screen and (max-width:700px) {
    .AltaPaymentContainer .content-container {
        display: flex;
        flex-direction: column;

        .alta_giftcardContainer {
            margin-top: 12px;

            input[type='text'] {
                width: 90%
            }

            .overview {
                margin-bottom: 10px;
            }
        }

        .altaContainer {
            .alta_altaContainer {
                padding: 10px;
            }

            .theLastPriceCon {
                height: 50px;
                padding: 0px;

                p {
                    margin: 10px;
                }
            }
        }
    }
}


/*---------SPINNER STYLE -----------*/
.spinner {
    width: 80px;
    height: 80px;
    position: absolute;
    // top: 50%;
    left: 45%;
    //    margin-top: -($spinner-size/ 2);
    //margin-left: -($spinner-size/ 2);
    border-radius: 50%;
    border: 5px solid black;
    border-right-color: #df5553;
    animation: rotateSpinner 800ms linear infinite;
}

@keyframes rotateSpinner {
    to {
        transform: rotate(360deg);
    }
}