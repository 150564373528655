@import '../../styles/mixins/general-mixins';

.Poster {
    position: relative;

    img {
        width: 100%;
        height: auto;
        cursor: pointer;
    }

    .poster-banner {
        position: absolute;
        bottom: 14%;
        padding: 3px 15px;
        font-weight: bold;
        color: white;
    }

    .threeD-banner {
        width: 38%;
        background-image: linear-gradient(to right,#370b67 35%,#67228b 60%,transparent);
    }

    .atmos-banner {
        width: 70%;
        right: 0;
        text-align: right;
        background-image: linear-gradient(to left, #0b670b 30%, #228b22 70%, transparent);
    }

    .age-banner {
        text-align: right;
        right: -7px;
        bottom: 6px;

        img {
            max-width: 100%;
        }
    }
}

.Movie {
    overflow: hidden;
    background-color: white;

    .Trailer {
        position: relative;
        width: 100%;
        padding-top: 33%;
        vertical-align: middle;
        overflow: hidden;
        transition: padding-top .4s ease-out;
        @include user-select(none);

        &.is-playing {
            padding-top: 56.25%;

            .player {
                margin-top: 0;
                margin-bottom: -4px;
                height: 100%;
                transform: none;
            }

            .logo {
                order: 1;
                align-self: center;
                margin-right: 2em;
            }
        }

        .trailer-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            font-size: 100px;
            color: RGBA(255, 255, 255, 0.6);
            transition: all .2s ease-in-out;
            z-index: 10;

            &:hover {
                color: RGBA(255, 255, 255, 0.8);
                cursor: pointer;
                background: none;
                transform: scale(1.1);
            }

            .icon-play {
                position: absolute;
                width: 100%;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .player {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            padding-top: 0 !important;

            .video-react-poster {
                background-size: cover;
            }

            .video-react-control-bar {
                font-size: 15px;
                background: #000000b9;
                margin-bottom: 0.5em;
            }

            .video-react-current-time,
            .video-react-duration,
            .video-react-time-divider,
            .video-react-big-play-button {
                display: none;
            }

            .video-react-play-progress {
                background-color: #B42911;

                &:before {
                    color: #d22a0d;
                }
            }
        }
    }

    .VersionSelector {
        width: 100%;
        float: left;
        color: #ccc;
        background-color: #2e2e2e;
        padding: 0 20px;
        position: relative;

        .version {
            display: table-cell;
            padding: 10px 15px;
            text-align: center;
            cursor: pointer;
            transition: all .2s ease-in-out;
            @include user-select(none);

            &.selected,
            &:hover {
                background-color: #eee;
                color: black;
                font-weight: bold;
                background-image: linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.08) 30%, rgba(255, 255, 255, 0) 55%);
            }
        }
    }

    .info-container {
        width: 100%;
        float: left;
        padding: 20px;
        box-shadow: inset 0 3px 13px -7px #666, inset 0 -8px 9px -9px #666;

        h1 {
            display: inline-block;
            margin-top: 0;
        }
    }

    .Poster {
        width: 25%;
        float: left;
        margin-right: 20px;
    }

    .ShowtimeSchedule {
        .week-nav {
            width: 10%;
            font-size: 34px;
        }

        .schedule {
            align-items: center;
        }
        
        .ShowtimeTypeList {
            padding-left: 10%;
            margin: 0 46px;
        }
    }

    @include fullscreen-poster;

    .Pictures {
        width: 101%;
        float: left;
        max-height: 200px;

        img {
            width: 33.33%;
            height: auto;
            float: left;
        }
    }
    //multi org
    .schedules {
        width: 100%;
        float: left;

        .organizer {
            width: 100%;
            float: left;
        }

        h3 {
            background: #c7c7c7;
            background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4));
            margin: 0;
            padding: 10px 20px;
        }
    }
    //--
}

.MovieInfo {
    width: 100%;

    p {
        width: 100%;
        margin: 3px 0;
        font-weight: bold;

        &.info {
            margin: 15px 0;
        }

        &.description {
            font-weight: normal;
            margin: 15px 0;
        }

        span {
            min-width: 100px;
            width: 10%;
            white-space: nowrap;
            float: left;
            display: inline-block;
            font-weight: normal;
        }

        label {
            width: 80%;
            display: inline-block;
        }
    }
}

.Movie .MovieInfo {
    width: 70%;
    float: left;
}

@media (max-width: 550px) {
    .Movie {
        .Trailer iframe {
            transform: translateY(-36%);
        }

        .Poster {
            width: 35%;
        }
    }
}
//responsive image at screens under 900px
@media (max-width:900px) {
    .Movie {
            .fullscreen-img img {
                object-fit:contain;
                width: 100%;
                height: 100vh;
            }
    }
}

@media (max-width: 550px) {
    .Movie {
        height: 100%;

        .MovieInfo {
            width: 100%;

            p label {
                width: 70%;
            }
        }

        .fullscreen-img {
            width: 100%;
            height: 100vh;
            
           
        }
  

        .Poster {
            width: 100%;
            padding: 0 0 10px;
        }
    }

    .Pictures {
        margin-bottom: 37px;
    }
}

@media (max-width: 450px) {
    .Movie {

        .Trailer {
            padding-top: 30%;

            .trailer-overlay {
                font-size: 50px;
            }

            iframe {
                height: 345%;
            }
        }
    }
}

@media (max-width: 365px) {
    .Movie {
        .Trailer {
            .trailer-overlay {
                font-size: 50px;
            }

            iframe {
                height: 330%;
                transform: translateY(-36%);
            }
        }

        .VersionSelector {
            .version,
            .version:first-of-type,
            .version:last-of-type {
                padding: 10px;
            }
        }

        .info-container h1 {
            display: block;
        }

        .MovieInfo p {
            label {
                width: 100%;
            }
        }
    }
}

@media (max-width: 337px) {
    .Movie {
        .Trailer {
            padding-top: 35%;

            .trailer-overlay .icon-play:before {
                visibility: hidden;
            }

            iframe {
                height: 330%;
                transform: translateY(-38%);
            }
        }
    }
}
