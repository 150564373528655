﻿@mixin six-columns {
    .six-columns {
        width: 16.6667%;
        display: flex;
        flex-direction: column;
        padding-bottom: 0.5rem;

        &:hover {
            cursor: pointer;
        }

        &:hover > .Poster {
            box-shadow: 0 8px 15px -7px black;

            img {
                transform: scale(1.05);
            }
        }

        .Poster {
            width: 100%;
            overflow: hidden;
            background-color: gray;

            img {
                transition: all .2s ease-in-out;
            }
        }

        .movie-info {
            width: 100%;
            min-height: 60px;
            background-image: linear-gradient(170deg, lightgray 0%, #eee 75%);

            h1 {
                font-size: 17px;
                min-height: 60px;
                overflow: hidden;
                margin: 0;
                padding: 8px;
            }

            .showtimes {
                width: 100%;
                display: table;
                margin-bottom: 6px;
                padding: 0 8px;
            }
        }

        &.first-in-row6 {
            clear: both;
        }
    }

    @media (max-width: 1100px) {
        .six-columns {
            width: 20%;

            &.first-in-row6 {
                clear: none;
            }

            &.first-in-row5 {
                clear: both;
            }
        }
    }

    @media (max-width: 930px) {
        .six-columns {
            width: 25%;

            &.first-in-row5 {
                clear: none;
            }

            &.first-in-row4 {
                clear: both;
            }
        }
    }

    @media (max-width: 750px) {
        .six-columns {
            width: 33.33%;

            &.first-in-row4 {
                clear: none;
            }

            &.first-in-row3 {
                clear: both;
            }
        }
    }

    @media (max-width: 560px) {
        .six-columns {
            width: 50%;

            &.first-in-row3 {
                clear: none;
            }

            &.first-in-row2 {
                clear: both;
            }
        }
    }

    @media (max-width: 310px) {
        .six-columns {
            width: 100%;

            .Poster {
                height: 350px;
            }

            &.first-in-row2 {
                clear: none;
            }
        }
    }
}
