﻿@mixin five-columns {
    .five-columns {
        width: 20%;
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        &:hover > .Poster,
        &:hover > .poster-container {
            box-shadow: 0 8px 15px -7px black;

            img {
                transform: scale(1.05);
            }
        }

        .Poster {
            width: 100%;
            overflow: hidden;
            background-color: gray;
            transition: all .2s ease-in-out;

            img {
                transition: all .2s ease-in-out;
                backface-visibility: hidden; // get rid of weird blur before and after transition..
            }
        }

        h1 {
            padding: 10px 10px 20px;
            font-size: 1.1rem;
        }

        .Showtime.with-location {
            padding: 25px 0;
        }

        .movie-info {
            width: 100%;
            display: table;
            min-height: 60px;
            text-align: center;
            background-image: linear-gradient(170deg, lightgray 0%, #eee 75%);

            .organizer {
                width: 100%;
                float: left;
            }

            .showtimes {
                width: 100%;
                display: table;
                margin-bottom: 6px;                
            }

            .read-more {
                width: 100%;
                height: 33px;
                float: right;
                text-align: right;
                margin: 0;
                transition: all .2s ease-in-out;
            }
        }

        &.first-in-row5 {
            clear: both;
        }
    }

    @media (max-width: 930px) {
        .five-columns {
            width: 25%;

            &.first-in-row5 {
                clear: none;
            }

            &.first-in-row4 {
                clear: both;
            }
        }
    }

    @media (max-width: 750px) {
        .five-columns {
            width: 33.33%;

            &.first-in-row4 {
                clear: none;
            }

            &.first-in-row3 {
                clear: both;
            }
        }
    }

    @media (max-width: 560px) {
        .five-columns {
            width: 50%;

            &.first-in-row3 {
                clear: none;
            }

            &.first-in-row2 {
                clear: both;
            }
        }
    }

    @media (max-width: 310px) {
        .five-columns {
            width: 100%;

            &.first-in-row2 {
                clear: none;
            }
        }
    }
}
