/* --------------MODAL STYLE------ */
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.modal-content {
    width: 457px;
    height:auto;
   color:white;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #1a1a1a;
    background-clip: padding-box;
    border: 1px solid #535353;
    left:120px;
    outline: 0;
    top:38px;
}
.modal-content-alert {
    width: 457px;
    height: auto;
    color: #101010;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #535353;
    left: 120px;
    outline: 0;
    min-height:472px;
    top: 20px;
    img {
        min-height:280px;
    }
    
    .alert-text {
        font-weight: bold;
        text-align: left;
        padding-left: 30px;
        font-size: 16px;
        display:flex;
        margin-top:20px;

        .seat-numbers {
            width: 26px;
            height: 26px;
            margin: 0 5px 0 8px;
            padding-top: 2px;
            text-align:center;
            font-weight:normal;
            font-size:16px;
            color:white;
            border-radius: 3px;
            background-color: #970301;
        }
    }

    .alert-info {
        text-align: left;
        font-size: 16px;
        padding:0px 30px;
        margin-top:20px;
        //font-family:libre Franklin;
        letter-spacing:0.48px;
    }

    .accept-btn {
        width: 397px;
        background-image: linear-gradient(to bottom, #0f8212 100%, #159b18 0%) !important;
        border-radius: 3px;
        color: #fff;
        margin-top: 5px;
        margin-bottom: 25px;
        margin-left:30px;
    }
}
.modal-container {
    // font-family: LibreFranklin;


    .title {
        text-align: left;
        padding-left: 28px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.48px;
        margin:20px 0px
       // font-family: LibreFranklin;
    }

    .description {
        margin: 10px 30px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.48px;
        font-weight: normal;
    }

    img {
        min-height: 279px !important;
        border: 1px solid #b8b8b8;
        object-fit: contain;
        margin:10px 30px;
    }

    .seat-numbers {
        width: 26px;
        height: 26px;
        margin: 0 5px 0 8px;
        padding: 3px 0;
        border-radius: 3px;
        background-color: #970301;
        font-weight: normal;
        font-size: 16px;
    }

    .close-btn {
        width: 397px;
        height: 34px;
        margin-right:30px;
        margin-top:15px;
        margin-bottom:25px;
      //  margin: 32px 0 0 16.8px;
        //padding: 4px 186px 6px;
        border-radius: 3px;
        color: white;
    }
}
.img_slide_left {
    height: 40px;
    width: 40px;
    border:1px solid white;
    color: black;
    border-radius: 5px;
    background-color: #fff;
    text-align:center;
    position:absolute;
    top:240px;
    left:-50px;
    padding-top:6px;
}
.img_slide_right {
    height: 40px;
    width: 40px;
    color: black;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
    position: absolute;
    top: 240px;
    right: -50px;
    padding-top: 5px;
    padding-left:10px;
}
button {
    min-width:40px;
}