
.filmklub-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    background-color: #0a0a0a;

    .Poster img {
        cursor: default;
    }

    .filmklub-header {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        //background-color: #eee;
        background-image: linear-gradient(94deg, #d4d4d4 -49%, #eee 51%);

        .filmklub-billet-btn {
            margin-top: 12px;
            width: 170px;
           // background-color: #982f2f;
            color: white;
            background-image: linear-gradient(258deg, #950200 56%, #9e0200 -46%);
            
            letter-spacing: 0.15px
        }
    }

    .MovieList {
        //display: flex;
        //flex-wrap: wrap;

        .MovieListItem {
            //width: 20%;
            //padding-bottom: 60px;



            .eventFilmsItems-movieInfo {
                height: 150px;
                padding-left: 10px;
                background-image: linear-gradient(154deg, #d4d4d4 30%, #eee 130%);
                //border:1px solid;
            }


            .eventFilmsItems-btn {
                background-image: none;
                background-color: darkred;
                color: white;
                width: 100px;
            }
        }
    }
}


/*.filmklub-container > .MovieList > .MovieListItem:nth-child(even) {
    background-color: #d8d8d8
}

.filmklub-container > .MovieList > .MovieListItem:nth-child(odd) {
    background-color: #eee
}*/
.filmklub-container > .MovieList > .five-columns {
    padding-bottom:0;
    justify-content:space-between;
}

.filmlist {
    border: 1px solid lightgrey;
    height: 500px;
    width: 20%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    flex-direction: column;
    //justify-content: space-between;
    //overflow:hidden;
    &:hover > .filmlist_poster-container {
        img {
            transform: scale(1.05);
        }
    }

    .filmlist_poster-container {
        height: 300px;
        width: 100%;
        transition: all .2s ease-in-out;
        overflow: hidden;
        position: relative;
        //border: 1px solid red;

        img {
            //object-fit: cover;

            width: 100%;
            height: 100%;
            transition: all .2s ease-in-out;
        }

        .filmlist_agebanner {
            position: absolute;
            bottom: 6px;
            right: 6px;
        }
    }
}
