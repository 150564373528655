@import '../../../styles/mixins/general-mixins.scss';

.VoucherInput {
    width: 100%;
    max-width: 650px;
    margin: 20px 0 5px;

    input {
        width: 74%;
        height: 33px;
        margin: 1% 0;
    }

    .send {
        @include button-green;
        width: 18%;
        min-width: 50px;
        margin-left: 1%;
        vertical-align: bottom;
    }

    .has-info {
        position: absolute;
        display: inline-block;
        padding: 11px 1.5%;
        cursor: pointer;

        .icon-info {
            font-size: 25px;
            cursor: pointer;
        }
    }

    .slide-down {
        margin: 20px 0;
    }

    .tool-tip {
        li {
            margin-left: 15px;
        }
    }

    .input-info {
        margin: 5px 0 20px;
    }
}

.VoucherDisplay {
    p {
        margin: 10px 0;
    }

    &.tickets {
        margin-left: 30px;
    }

    .clear {
        display: inline-block;
        margin: 0 20px;
        color: darkred;
        font-weight: bold;

        &:hover {
            cursor: pointer;
            color: red;
        }
    }

    .icon-close {
        margin-top: 2px;
    }
}

.VoucherErrors {
    color: #bb1212;
    margin: 5px 0 20px;
}
