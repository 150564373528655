.CircusListContainer.one-column {
    .header {
        width: 100%;
        float: left;
        position: relative;

        .Poster {
            width: 20%;

            img {
                cursor: default;
            }
        }

        h1 {
            width: 80%;
            margin: 0;
            padding: 20px;
            color: white;
            background: black;
            background-image: linear-gradient(148deg, #333333 16%, black 90%);
            cursor: default;
        }

        p {
            padding: 20px;
            margin: 0;
            width: 80%;
            float: left;
        }

        .search {
            width: 220px;
            height: 35px;
            margin: 20px;
            position: absolute;
            right: 5px;
            top: 0;
            background: white;
            font-size: 1em;
        }
    }

    .organizer {
        width: 100%;
    }

    .movie-info {
        width: 100%;
        padding-left: 0;
        margin-top: -2px;

        .showtime-row {
            width: 100%;
            padding: 15px 20px;
            margin: 3px 0;
            background-image: linear-gradient(148deg, #f2f2f2 16%, #ebebeb 90%);
        }
    }

    .date-time {
        flex-basis: 60%;
        font-weight: bold;
        white-space: nowrap;
        padding-right: 20px;
        min-width: 210px;
    }

    .buy-tickets {
        padding-right: 25px;
    }

    .primary-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-basis: 38%;
        margin-right: 15px;
    }

    .info-container {
        span {
            vertical-align: middle;
        }
    }
    
    .show-info {
        color: white;
        background-color: #9f9f9f
    }

    .movie-info .read-more .MovieInfo {
        font-size: 1em;
    }
}

@media(max-width: 900px) {
    .CircusListContainer.one-column {
        .header {
            display: flex;
            flex-wrap: wrap;

            h1 {
                order: 1;
                width: 100%;
            }

            .Poster {
                order: 2;
                width: 25%;
            }

            p {
                order: 3;
                width: 75%;
                font-size: 1rem;
            }
        }
    }
}

@media(max-width: 660px) {
    .CircusListContainer.one-column {
        .header {
            p {
                font-size: 0.9rem;
            }

            .search {
                position: initial;
                order: 4;
                margin-left: auto;
            }
        }

        .showtime-row {
            flex-wrap: wrap;
        }

        .primary-container {
            flex-basis: 100%;
            margin-bottom: 12px;
        }

        .buy-tickets {
            margin-left: auto;
            padding-right: 0;
        }
    }
}

@media(max-width: 375px) {
    .CircusListContainer.one-column {
        .header {
            display: flex;
            flex-wrap: wrap;

            h1 {
                order: 2;
                width: 85%;
                font-size: 1.5rem;
                vertical-align: middle;
                display: inline;
            }

            .search {
                width: 100%;
                margin-left: 20px;
            }

            .Poster {
                order: 1;
                width: 15%;
                margin-bottom: -4px;
            }

            p {
                width: 100%;
                font-size: 0.85rem;
            }
        }


        .date-time {
            flex-basis: 62%;
        }

        .buy-tickets {
            flex-basis: 38%;
            margin-bottom: 5px;
        }
    }
}
