@import '../../../styles/mixins/general-mixins';

.ExtraPurchaseContainer {
    width: 100%;
    display: table;
    padding-bottom: 0;

    h1,
    .extra-text {
        padding-left: 20px;
    }
}

.ExtraPurchaseList {
    display: flex;
    flex-wrap: wrap;
   // padding: 0 20px;
}

.ExtraPurchaseItem {
    max-width: 33.33%;
    display: flex;
    flex-direction: column;
    padding: 0 20px 40px;
    text-align: center;

    &:first-of-type {
       // padding-left: 0;
    }

    &:last-of-type {
     //  padding-right: 0;
    }

    h3 {
        margin: 5px 0 0;
    }

    img {
        max-width: 100%;
        margin: auto;
        height:196px
    }

    .name-and-price {
        font-size: 1rem;
    }

    .note {
        color: #6b6b6b;
    }
}


@media (max-width: 700px) {
    .ExtraPurchaseItem {
        max-width: 50%;
    }
}

@media (max-width: 500px) {
    .ExtraPurchaseItem {
        max-width: 100%;
    }
}
