.NewslettersContainer {
    padding: 20px;

    .newsletter {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    input[type=checkbox] {
        margin-right: 10px;
    }

    form {
        max-width: 400px;
        padding: 0 0 40px;

        label {
            width: 40px;
            margin-right: 20px;
        }

        .textbox {
            width: 220px;
            height: 33px;
        }

        .error {
            margin-left: 60px;
        }
    }

    .subscribed {
        color: green;
        font-weight: bold;
        opacity: 0;
    }

    .unsubscribed {
        color: red;
        font-weight: bold;
        opacity: 0;
    }

    .fade-in-out {
        animation: fade-in-out 2s linear;
    }

    @keyframes fade-in-out {
        0% {
            opacity: 1
        }
                
        50% {
            opacity: 0.5
        }

        100% {
            opacity: 0
        }

    }
}
