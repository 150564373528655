@import '../../../styles/mixins/general-mixins';

.MovieListContainer.week {
    .DatePicker {
        width: 100%;
        justify-content: flex-end;
        position: initial;

        .day {
            margin-right: 0;
        }

        .Calendar {
            max-width: 1170px;
            margin: auto;
        }
    }

    .MovieList {
        background: none;
    }

    .MovieListItem {
        width: 100%;
        display: flex;
        color: #2c2c2c;
        margin-bottom: 50px;
    }

    h1 {
        height: auto;
        background: black;
        color: white;
        margin: 0;
        padding: 10px 20px;
        cursor: pointer;
    }

    .poster-container {
        width: 20%;
    }

    .movie-info {
        width: 80%;
        display: flex;
        flex-direction: column;
        background: #eee;
        background-image: linear-gradient(136deg, #f2f2f2 -11%, #ebebeb 48%);
    }

    .ShowtimeSchedule, 
    .schedule {
        flex: 1;
    }

    .ShowtimeTypeList {
        padding: 0 85px 0 65px;
    }

    .movie-info-btn {
        @include button-default;
        @include button-gray;
        position: absolute;
        right: 5px;
        bottom: 6px;
    }
}
