@import 'one-column';
@import 'two-columns';
@import 'five-columns';
@import 'six-columns';
@import '../../../styles/mixins/general-mixins';

.ProgramContainer {
    width: 100%;
    min-height: 460px;
}

.special-title {
    margin: 0;
    padding: 10px 20px;
    opacity: .9;
    font-size: 30px;
    font-weight: normal;
    text-align: center;
    background-image: linear-gradient(170deg,#bbb 0%,#ddd 75%);
}

.special-event {
    margin-bottom: 20px;
}

.no-events {
    width: 100%;
    float: left;
    text-align: center;
    background-color: #eee;
    margin: 0;
    padding: 20px;
}

.five-columns .MovieList {
    display: flex;
    flex-wrap: wrap;
}

.MovieList {
    width: 100%;
    height: 100%;
    background-color: #eee;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    

    a {
        text-decoration: none;
    }

    h1 {
        color: black;
        overflow: hidden;
        margin: 0;
    }

    .MovieListItem {
        position: relative;
       
    }

    .Poster img {
        margin-bottom: -6px;
    }

    @include six-columns;
    @include five-columns;
    @include two-columns;
    @include one-column;
}

.MovieListContainer.one-column .MovieList {
    background: none;
}

.AllForOne .MovieList {
    .two-columns .showtimes {
        padding: 0 3px;
    }

    .showtimes .date {
        font-weight: bold;
        margin-bottom: 3px;
    }

    .location {
        margin-top: 10px;
    }

    .flex-container {
        display: flex;
        flex: 1;
    }

    .organizer {
        width: 100%;
    }

    .movie-info {
        display: flex;
        flex-direction: column;
        flex: 1;

        .showtimes {
            width: 50%;
            float: left;
            text-align: center;
        }
    }

    .MovieListItem.one-column {
        .movie-info .showtimes {
            text-align: left;
        }
    }

    .read-more {
        margin-top: auto;
        text-align: right;
    }

    .more-showtimes {
        padding: 7px;
        background: none;
        transition: all .2s ease-in-out;

        &:hover {
            box-shadow: 0 8px 15px -7px black;
            background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(156, 156, 156, 0.4));
        }
    }
}

.AllForOne .MovieList,
.alt-all {
    .more-showtimes {
        padding: 7px;
        background: none;
        transition: all .2s ease-in-out;

        &:hover {
            box-shadow: 0 8px 15px -7px black;
            background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(156, 156, 156, 0.4));
        }
    }
}

.alt-all {
    .Showtime {
        width: 46%;
    }
}

.all-future {
    margin-top: 70px;
    text-align: center;

    .page-header {
        text-align: left;
        margin-bottom: 40px;
    }
}

@media (max-width: 650px) {
    .page-header.orderflow {
        margin-top: 52px;
    }

    .AllForOne .MovieList .MovieListItem.one-column .movie-info .showtimes {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .MovieList .MovieInfo .description {
        display: none;
    }
}

@media (max-width: 245px) {
    .MovieList {
        .topbar-workaround {
            margin-top: 106px;
        }
    }
}
