
button:hover {
    box-shadow:none;
    cursor:pointer;
    
}
button {
    border: none;
    outline: none;
    background: transparent;
    //color:white
}

.ebilletProgram, .AllMovies{
    font-family:oswald;
    background-color:#262626;
    .chooseFromContainer {
        //border: 1px solid red;
       //background-color: #3b3b3b;
        height: 121px;
        color: white;
        box-sizing: border-box;
        display: flex;
        // padding:5px;

        .Calendar {
            top: 100px;
            max-width: 1170px;
            margin: 0 auto;
        }

        button {
            min-width: 40px,
        }
    }


    .region{
        /* border:1px solid; */
        max-width: 1170px;
        background-color: #3b3b3b;
        //height: 121px;
        /* padding: 0px 50px; */
        color: white;
        /* border-radius: 2px; */
        display: flex;
        /*margin: 0 auto*/
        /* justify-content: space-around; */
    }

    .region-title, .choose-title {
        font-size: 20px;
        font-weight: 600;
        /* line-height: 1.05px; */
    }

    //vælge region style
    .regionPointer:hover {
        cursor:pointer
    }
        .chooseRegion {
        width: 177px;
        z-index:99;
        /* height:33px; */
        border-radius: 2px;
        border: 1px solid #757575;
        color: #999;
        // margin-top: -15px;
        background-color: #3a3a3a;
        position: relative;
    }

    .region {
        margin: 0;
        //padding: 5px;
        letter-spacing: 0.44px;
    }

    .one {
        border-radius: 2px;
        background-color: #f7f7f7;
        padding: 0px 7px;
        font-size: 12px;
        position: absolute;
        top: 7px;
        right: 45px;
        color: black
    }

    .rotated {
        transform: rotate(180deg);
        margin-top: 10px;
    }

    .checkCheckbox {
        border: 1px solid #757575;
        width: 19px;
        height: 19px;
    }
    // vælge biograf style
    .chooseCinema {
        border: 1px solid #757575;
        width: 378px;
        /* height:33px; */
        border-radius: 2px;
        background-color: #3a3a3a;
        // margin-top: -15px;
        color: #999;
    }

    .scroll {
        p:hover {
            cursor: pointer;
            color: white;
            font-size: 20px
        }
    }

    .scroll::-webkit-scrollbar {
        width: 33px;
    }

    .scroll::-webkit-scrollbar-thumb {
        background: #3b3b3b;
        border-right: solid 2px transparent;
        box-shadow: inset 1px 1px 1px 100px #757575;
    }

    .scroll::-webkit-scrollbar-track {
        margin: 2px;
    }
}


//program style
.programFilmContainer {
    //border:1px solid ;
    background-color: #262626;
    color: white;

    .program-header {
        display: flex;
        justify-content: space-between;
        //padding: 5px;
    }

    .all-film-program {
        display:inline-flex;
        flex-wrap:wrap;
        gap:7.5px;
        justify-content:flex-start;
        margin: 20px 0px;
        font-family:Oswald;
    }
}



//sorting style
.sorting {
    /* border:1px solid white; */
    padding-top: 50px;
    margin-right:-1px;
    display: grid;
    grid-template-columns: .1fr 1fr;
}

.sortingContainer {
    border: 1px solid #757575;
    width: 228px;
    color: #999;
    background-color: #3a3a3a;
    /* height:33px; */
    margin-top: -5px;
    margin-left: 5px;
    position: relative;
}

.sortingDiv {
  
        border: 1px solid #757575;
        background-color: #3a3a3a;   
        color: #d2d2d2;
        margin-left: -1px;
        padding: 0px 8px;
        position: absolute;
        z-index: 30;
        width: inherit;
        p:hover {
        cursor: pointer
    }
}

.line {
    position: absolute;
    border-left: 0.1px solid #757575;
    top: 0;
    height: 31px;
    right: 35px
}

.rotated {
    transform: rotate(180deg);
    margin-bottom: 15px;
}

.ellipsis-twoline {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* width:228px; */
    height: 76px;
    white-space: pre-wrap;
    /* padding:10px */
}


 /*--------------------------- MEDIA QUERIES  ---------------*/
@media only screen and (max-width:1170px) {
    .mblMenu {
        flex-direction:column
    }
}