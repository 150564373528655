.multibio {
    .flex-container {
        display: flex;
    }

    .available-organizers {
        background-color: #101010;
        background-image: linear-gradient(266deg, #b7b7b7, #cdcdcd 0%);
    }

    .MovieList .one-column h3 {
        background-color: #101010;
        background-image: linear-gradient(266deg, #b7b7b7, #cdcdcd 0%);
        padding: 7px 20px 10px;
    }

    .organizer {
        width: 50%;
        float: left;
    }

    .MovieList .one-column .movie-info .read-more.future {
        background: none;
        float: none;
        padding: 20px 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 650px) {
    .multibio .flex-container {
        flex-direction: column;

        .organizer {
            width: 100%;
            min-height: 140px;
        }
    }
}
