@font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.eot?ntvpe4');
    src: url('/assets/fonts/icomoon.eot?ntvpe4#iefix') format('embedded-opentype'), 
         url('/assets/fonts/icomoon.ttf?ntvpe4') format('truetype'), 
         url('/assets/fonts/icomoon.woff?ntvpe4') format('woff'), 
         url('/assets/fonts/icomoon.svg?ntvpe4#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-senior-bio:before {
    content: "\e90f";
}

.icon-chevrons-up:before {
    content: "\e906";
}

.icon-chevrons-down:before {
    content: "\e909";
}

.icon-star:before {
    content: "\e838";
}

.icon-check:before {
    content: "\e900";
}

.icon-calendar:before {
    content: "\e901";
}

.icon-sort-by-time:before {
    content: "\e902";
}

.icon-sort-by-alphabet:before {
    content: "\e903";
}

.icon-circle-plus:before {
    content: "\e904";
}

.icon-circle-minus:before {
    content: "\e905";
}

.icon-all-programs:before {
    content: "\e907";
}

.icon-play:before {
    content: "\ea15";
}

.icon-baby-bio:before {
    content: "\eb42";
}

.icon-required:before {
    content: "*";
}

.icon-close:before {
    content: "\e90d";
}

.icon-tool-tip:before {
    content: "\e908";
}

.icon-info:before {
    content: "\e90c";
}