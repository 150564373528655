@import '../../styles/mixins/general-mixins.scss';

.no-scroll {
    overflow: hidden;
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    color: white;
    font-size: 20px;
    background-color: rgba(161, 161, 161, 0.95);
    opacity: 0;
    visibility: hidden;

    .overlay-content {
        margin: auto;
        text-align: center;
        padding: 20px;
        margin-top: 150px;
        background-color: rgba(0,0,0,0.6);
        position: relative;

        .red {
            @include button-red;
        }

        .green {
            @include button-green;
        }

        input[type=button] {
            margin: 0 10px 20px 10px;
        }

        .reload {
            color: black;
        }
    }
}

.Queue {
    label {
        color: #bbb;
        font-size: 1rem;
    }
}

.Info {
    .overlay-content {
        max-width: 700px;

        .buttons {
            width: 100%;
        }

        input[type=button] {
            margin: 5px;
        }
    }
}

.Error {
    .code {
        font-size: 12px;
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
}

.CookiePolicy {
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 100;
    background-color: rgba(0,0,0,0.6);
    color: white;

    .content {
        margin: auto;
        padding: 10px 20px;

        .text {
            width: 70%;
            display: inline-block;

            h1, p {
                margin: 5px 0;
            }
        }

        .buttons {
            width: 30%;
            display: inline-block;
            text-align: right;

            .read-more {
                background: gray;

                &:hover {
                    background: #8b8b8b;
                }
            }
        }
    }
}

@media (max-width: 750px) {
    .CookiePolicy .content {
        .text {
            width: 60%;
        }

        .buttons {
            width: 40%;
        }
    }
}


@media (max-width: 580px) {
    .Info .overlay-content {
        margin-top: 0;
    }

    .CookiePolicy .content {
        .text {
            width: 100%;
        }

        .buttons {
            width: 100%;
            text-align: center;
        }
    }
}


@media (max-height: 450px) {
    .overlay .overlay-content {
        margin-top: 0;
    }
}
