﻿@mixin item-hover {
    cursor: pointer;

    &:hover,
    &.active {
        text-decoration: underline;
        color: gray;
    }
}

@mixin toogle-hide-show {
    visibility: hidden;
    opacity: 0;
    transition: all ease-in 0.5s;

    &.show {
        visibility: visible;
        opacity: 1;
    }
}

@mixin main-content {
    width: 80%;
    float: left;
    padding: 0 30px;
}

@mixin user-select($select) {
    -webkit-user-select: $select; /* Chrome all / Safari all */
    -moz-user-select: $select; /* Firefox all */
    -ms-user-select: $select; /* IE 10+ */
    user-select: $select;
}

@mixin button-default {
    background-color: buttonface;
    background-image: linear-gradient(rgba(156, 156, 156, 0.1), rgba(193, 197, 193, 0.4));
    transition: all 0.2s ease-in-out;
    border-radius: 3px;

    span {
        margin-right: 10px;
    }

    &:hover {
        box-shadow: 0 8px 15px -7px black;
    }

    &.dim,
    &.disabled {
        cursor: default;
    }

    &.disabled {
        color: gray;
        background: #cfcfcf;
        font-weight: normal;
        pointer-events: none;

        &:hover {
            box-shadow: none;
            transition: none;
            font-weight: normal;
        }
    }
}

@mixin button-green {
    color: white;
    background-color: green;
    background-image: linear-gradient(rgba(0, 160, 0, 0.1), rgba(0, 200, 0, 0.4));

    &:hover {
        background-color: darkgreen;
    }
}

@mixin button-gray {
    color: white;
    background-color: gray;
    background-image: linear-gradient(rgba(156, 156, 156, 0.1), rgba(193, 197, 193, 0.4));

    &:hover {
        background-color: #696969;
    }
}

@mixin button-red {
    color: white;
    background-color: darkred;
    background-image: linear-gradient(rgba(152, 0, 0, 0.1), rgba(222, 0, 0, 0.4));

    &:hover {
        background-color: #730000;
    }
}


@mixin fullscreen-poster {
    .fullscreen-img {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        overflow: auto;
        text-align: center;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.65);

        &.Poster {
            overflow: hidden;
            width: 100%;
            display: inline-block;
            padding-right: 0;
        }

        img {
            width: auto;
            height: 100%;
            float: none;
        }
    }
}
