@import '../../styles/mixins/general-mixins';

.Showtime {
    @include button-default;
    @include button-green;
    width: 98px;
    height: 32px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size:16px;
    font-weight: bold;
    text-decoration: none;
    margin-top: 5px;

    &.hidden {
        width: auto !important;
        padding: 5px 20px;
    }

    .count-down {
        flex-basis: 100%;
        font-size: 0.85rem;
        font-weight: bold;
        color: gray;
        background: #cfcfcf;
    }

    &.has-count-down {
        flex-wrap: wrap;
        height: auto;
    }

    .ShowtimeType {
        position: absolute;
        margin: 0;
        top: 0;
        bottom: 0;
        right: -18px;
        color: black;
    }
}
.mobileSchedule {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;

    .ShowtimeScheduleWeek {
        display: flex;
        font-size: 10px;
        justify-content: space-between;
        padding: 2px;

        .showtimes-on-date {
            text-align: center;
            .count-down {
                display:none;
            }

            .ShowtimeType {
                right:-10px;
                .showtime-type-icon {
                    width: 11px;
                    height: 12px;
                    font-size: 8px;
                }
            }
        }

        .date {
            width: 45px;
        }

        .Showtime {
            width: 49px;
            height: 24px;
            border-radius: 2px;
            margin-top:2px;
        }
    }

    .weeks {
        //border: 1px solid black;
        display: flex;
        justify-content: space-between;
        height: 40px;
        background-color: #c5c5c5;
        border-bottom-color:#fff;
        border-bottom-style:outset;
        border-bottom-width:0.1px;
        //color: white;

        .week-nav {
           
             margin-top: 1px;
            text-align: center;
            font-size: 28px;
            color: white;
            width: 75px;
            background-color: #808080;
            user-select: none;
            //border-bottom: 1px solid white;

            &:hover {
                cursor: pointer;
                font-weight: bold;
            }

            &.disabled {
                color: lightgrey;
                //opacity: 0.2;
                pointer-events: none;

                &:hover {
                    cursor: default;
                    font-weight: normal;
                }
            }
        }
    }
}
.ShowtimeSchedule {
    display: flex;
    width: 100%;
    flex-direction: column;

    .schedule {
        display: flex;
        margin-bottom: 16px;
        justify-content: space-between;
        //flex-wrap: wrap;
    }

    .ShowtimeScheduleWeek {
        width: 94%;
        max-width: 828px;
        min-height: 144px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .week-nav {
        margin-top: 14px;
        margin: 20px;
        text-align: center;
        font-size: 28px;
        color: #6f6f6f;
        user-select: none;

        &:hover {
            cursor: pointer;
            font-weight: bold;
        }

        &.disabled {
            opacity: 0.3;
            pointer-events: none;

            &:hover {
                cursor: default;
                font-weight: normal;
            }
        }
    }

    .ShowtimeScheduleWeek {
        width: 94%;
        max-width: 828px;
        min-height: 144px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .showtimes-on-date {
            display: flex;
            flex-direction: column;
            padding: 2px 10px;
            text-align: center;
            align-items: center;

            .date {
                width: 98px;
                font-size: 17px;
                margin: 20px 0 8px;
                font-weight: normal;
                white-space: nowrap;
            }
        }
    }
}
//showtimeSchedule css ends above

.ShowtimeSchedule2 {
    width: 100%;
    display: flex;
    flex-direction: column;

    .schedule {
        display: flex;
        // margin-bottom: 16px;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .movieinfo-btn {
        bottom: 7px;
        background-color: darkgrey;
        color: white;
        position: absolute;
        right: 90px;
    }

    .showtimeTypeContainer {
        //border: 1px solid lightgrey;
        background-color: antiquewhite;
        display: flex;
        //min-height: 45px;
        border-radius:2px;
        //margin-top:-5px
        //position:relative;
        //padding:0px 118px 0px 65px;
    }

    .week-nav {
        //margin-top: 14px;
        //margin: 20px;
        text-align: center;
        font-size: 28px;
       // color: #6f6f6f;
        user-select: none;
        //height:0px;
        &:hover {
            cursor: pointer;
            font-weight: bold;
        }

        &.disabled {
            opacity: 0.3;
            pointer-events: none;

            &:hover {
                cursor: default;
                font-weight: normal;
            }
        }
    }

    .ShowtimeScheduleWeek {
        width: 94%;
        // max-width: 828px;
       // min-height: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: 20px;

        .leftRightShowtimes {
            display:flex;
            //width:45%;
            //justify-content:space-between;
        }
            .nextShowBtn {
            display: flex;
            cursor: pointer;
            // margin-left: 25%;
            /* &:hover {
                margin-left: 10px;
            }*/
        }

        .previousShowBtn {
            display: flex;
            cursor: pointer;
            margin-right:30px;
            // margin-left: 25%;
            /* &:hover {
                margin-right: 10px;
            }*/
        }
    }

    .moviesArrows {
        background-color: darkgrey;
        width: 6%;
        display: flex;
        height:100%;
        flex-direction: column;
        justify-content: center;
    }

    .showtimes-on-date {
        display: flex;
        flex-direction: column;
        padding: 0px 10px;
        text-align: center;
        align-items: center;
        margin-top:-5px;
        .date {
            width: 98px;
            font-size: 17px;
            margin: 20px 0 8px;
            font-weight: normal;
            white-space: nowrap;
        }
    }
}

.ShowtimeTypeList {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    min-height: 44px;
    padding: 0 85px 0 65px;
    

    .ShowtimeType {
        margin: 8px 40px 10px 0;
        font-family:oswald;
        font-size:17px;
    }
}

.ShowtimeType {
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
        margin-right: 10px;
    }

    .showtime-type-icon {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fbe0bd;
        border: 1px solid #2c2c2c;
        border-radius: 2px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.23);
        font-family: 'Roboto Mono';
        font-size: 13px;
        font-weight: bold;
        color: #2c2c2c;
    }
}


.TopBar .ShowtimeType .showtime-type-icon {
    width: 25px;
    height: 25px;
    line-height: 1.9;
}


.six-columns,
.five-columns {
    .Showtime {
        width: 42%;
        float: left;
        padding: 5px 5px 5.5px;
        margin: 4% 4% 0
    }

    .ShowtimeTypeList {
        padding: 8px;

        .ShowtimeType {
            width: 100%;
            margin: 6px 0;
        }
    }
    //Alle film 1 organizer
    &.AllForOne {
        .showtimes {
            padding: 0 8px;
        }

        .Showtime {
            width: 100%;
            margin: 4% 0;
        }
    }
    //---
    //Special Events
    .special-event {
        .Showtime {
            width: 80%;
            float: none;
            margin-top: 5px;
            display: inline-block;
            text-align: center;
        }
    }
    //----
    //Ticketmaster
    .external {
        text-align: center;

        .Showtime {
            width: 80%;
        }
    }
    //----
}

.AllForOne {
    .showtime-row {
        width: 50%;
        display: flex;
        float: left;
        justify-content: center;
    }
}

.one-column {
    .Showtime {
        width: 130px;
    }

    .location {
        margin-right: 20px;
    }

    .type {
        margin-left: 20px;
    }
}

.one-column {
    .ShowtimeType,
    .ShowtimeTypeList {
        display: none;
    }
}


/*MEDIA QUERY FOR SHOWTIMES IN SMALL SCREEN*/
@media screen and (min-width:431px) and (max-width:660px) {
    .MovieListContainer .week2 .movie-info h1 {
       /* font-size:18px;
        height:48px;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
        background-color:red;*/
    }
    .ShowtimeSchedule2 {
        .ShowtimeScheduleWeek {
            width: 90%;
            .ty {
               // border:1px solid red
            }
        }

        .moviesArrows {
            width: 10%;
        }


        .ShowtimeTypeList {
            padding: 5px;
            flex-direction: column
        }

        .movieinfo-btn {
            right: 5px;
        }
    }
}
    @media (max-width:430px) {
        .week2 {
            width: 94%;
            margin: 0 auto;

            .Showtime .count-down {
                font-size: 10px;
            }
        }

        .ShowtimeSchedule2 {
            margin-left: 0px;

            

            .ShowtimeTypeList {
                //padding: 0 15px;
                padding: 0px 0px 0px 30px;

                .ShowtimeType {
                    font-size: 12px;
                    //margin:0;
                }
            }

            .showtimeTypeContainer {
                margin-left: -82px;
                // margin-top:-10px;
            }

            .moviesArrows {
                background-color: transparent
            }

            .movieinfo-btn {
                top: 64px;
                left: 24%;
                width: 100px;
            }
            // background-color:white;
            .week-nav {
                width: 20%;
                min-width: 65px;
                height: 40px;
                position: absolute;
                top: 113px;
                color: white;
                right: 0px;
                border-bottom: 1px solid rgba(255,255,255,0.1);
                padding-top: 5px;
                background-color: #a9a9a9;

                &.disabled {
                    opacity: 1;
                    color: lightgrey;
                }
            }
        }

        .ShowtimeSchedule2 #leftarrow {
            left: 0px;
            position: absolute;
            width: 20%;
            min-width:65px;
        }
        .ShowtimeSchedule2 #leftarrow svg {
            //width:11px
        }
        .ShowtimeSchedule2 .schedule {
            //margin-top:70px;
            margin-bottom: 5px;
            // padding: 3px;
            margin-left: -77px;
            margin-top: 5px;
            // border:1px solid red;
            //background-color: #eee;
        }

        .ShowtimeSchedule2 .showtimes-on-date {
            width: 49px;

            .date {
                font-size: 10px
            }
        }

        .MovieListContainer.week2 .Showtime {
            width: 47px;
            height: 24px;
            font-size: 10px;
            margin-left: 4px;
            font-weight: normal;

            .ShowtimeType {
                right: -10px;
                top: -1px;
            }
        }

        .MovieListContainer.week2 .ShowtimeSchedule2 .showtimes-on-date
        .date {
            font-size: 10px;
        }

        .MovieListContainer.week2 .ShowtimeType .showtime-type-icon {
            width: 11px;
            height: 12px;
            font-size: 8px;
        }

        .ShowtimeSchedule2 .ShowtimeScheduleWeek {
            margin-top: -4px;
            background-color: #eee;
            width: 100%;
            min-height: 120px;
            padding-top:30px;
            .previousShowBtn, .nextShowBtn {
                //margin-left:35%;
                font-size: 12px;
               // width:150px;
               /* position:absolute;
                margin-top:35px;*/
            }
        }
    }

@media screen and (max-width:300px) { 
    .ShowtimeSchedule2 .movieinfo-btn {
        left:30%
    }
}

@media screen and (max-width:400px) {
    .ShowtimeSchedule2 .schedule {
        margin-left: -72px;
    }
}
@media screen and (max-width:380px) {
    .ShowtimeSchedule2 .schedule {
        margin-left: -70px;
    }
    .MovieListContainer.week2 .Showtime {
        width: 72px
    }
    .ShowtimeSchedule2 .ShowtimeScheduleWeek {
        padding-left:20px
    }

}
@media screen and (max-width:364px) {
    .MovieListContainer.week2 .Showtime {
       // width: 41px
    }

}
@media screen and (max-width:379px) {
    
    }
@media screen and (min-width:297px) and (max-width:370px) {
    .MovieListContainer.week2 .Showtime {
         width: 60px
    }
}
@media screen and (min-width:335px) and (max-width:365px) {
    .ShowtimeSchedule2 .schedule {
        //margin-left: -66px;
    }
}
@media screen and (max-width:365px) {
    .ShowtimeTypeList {
        /*padding:0px 0px 0px 30px;*/
    }

    

    .ShowtimeSchedule2 .showtimes-on-date {
        //width: 40px;

        .date {
            //width: 36px;
            font-size: 10px;
        }
    }
}


@media screen and (max-width:379px) {
    .ShowtimeSchedule2 .ShowtimeScheduleWeek .nextShowBtn {
        width:200px;
        margin-left:70px;
    }
    .Showtime {
       // padding-left:7px;
       // justify-content:left
    }
    .MovieListContainer.week2 .ShowtimeType .showtime-type-icon {
        margin-top:3px
    }
}

@media screen and (max-width:1050px) {
    .ShowtimeSchedule2 .ShowtimeScheduleWeek .leftRightShowtimes {
        //border:1px solid ;
     //  width: 64%
    }
}
@media screen and (max-width:430px) {
    .ShowtimeSchedule2 .ShowtimeScheduleWeek .leftRightShowtimes p{
        font-size:12px;
        padding-top:2px
    }
    }
@media screen and (max-width:665px) {
    .ShowtimeSchedule2 .ShowtimeScheduleWeek .leftRightShowtimes  {
       //width:70%
    }
}
@media screen and (max-width:585px) {
    .ShowtimeSchedule2 .ShowtimeScheduleWeek .leftRightShowtimes {
        width: 100%
    }
}
@media screen and (max-width:385px) {
    .ShowtimeSchedule2 .ShowtimeScheduleWeek .leftRightShowtimes {
       // width: 100%
      // border:1px solid red;
       width:65%;
       margin:0 auto;
    }
}

@media screen and (min-width: 1170px) {
    .ShowtimeSchedule2 .ShowtimeScheduleWeek {
        padding:10px 20px
    }

}
/*@media screen and (max-width:430px) {
    .ShowtimeSchedule2 .ShowtimeScheduleWeek .leftRightShowtimes {
        width: 90%
    }
    .ShowtimeSchedule2 .ShowtimeScheduleWeek .leftRightShowtimes p{
     
        padding-top:7px;
        font-size:13px;
    }
    .ShowtimeTypeList {
        width:100%
    }
}*/