.Summary {
    width: 100%;
    display: table;
    background: #eee;

    &.fixed-nav .summary-info {
        background-image: linear-gradient(to top, rgba(179, 179, 179, 0.1), #eee);
    }

    &.fixed-nav h3 {
        background-image: linear-gradient(to top, rgba(255,255,255,0), #dedede);
    }

    h3, .summary-info {
        float: left;
        height: 100%;
    }

    h3 {
        width: 21%;
        padding: 18px 20px;
        margin: 0;
        white-space: nowrap;

        &:hover {
            cursor: pointer;
        }
    }

    .icon-chevrons-down,
    .icon-chevrons-up {
        padding: 4px 5px 0 0;
    }

    .summary-info {
        width: 79%;
        padding: 23px;
        background: white;
        color: black;

        .SelectedTickets {
            display: inline;
        }

        .order {
            width: 70%;
            float: left;
        }

        .total-price {
            width: 30%;
            float: right;
            text-align: right;
            font-weight: bold;
        }
    }

    .PriceInfo {
        &.closed {
            height: 0;
            overflow: hidden;
            margin-top: 0;
        }

        transition: height .2s cubic-bezier(0, 1, 0.5, 1);
        width: 320px;
        float: left;
        padding: 0 20px;
        margin-top: 10px;

        p {
            margin: 10px 0;

            &:last-of-type {
                margin-bottom: 25px;
            }
        }

        span {
            float: right;
            display: inline-block;
        }

        .disabled {
            color: #979797;
        }
    }
}

@media (max-width: 845px) {
    .Summary {
        h3 {
            width: 28%;
        }

        .summary-info {
            width: 72%;
        }
    }
}

@media (max-width: 650px) {
    .Summary {
        h3 {
            width: 100%;
            padding-left: 17px;
        }

        &.fixed-nav .summary-info {
            background-image: none;
        }

        .summary-info {
            width: 100%;
            padding: 20px;

            .order {
                width: 64%;
            }

            .SelectedTickets span {
                width: 100%;
            }

            .total-price {
                width: 36%;
            }
        }
    }
}

@media (max-width: 500px) {
    .Summary {

        h3,
        .summary-info,
        .PriceInfo {
            width: 100%;
        }
    }
}


@media (max-width: 424px) {
    .Summary {
        margin-bottom: 80px;
    }
}
