@import '../../../styles/mixins/general-mixins';

.MovieListContainer.week2 {
    .DatePicker {
        width: 100%;
        justify-content: flex-end;
        position: initial;

        .day {
            margin-right: 0;
        }

        .Calendar {
            max-width: 1170px;
            margin: auto;
        }
    }

    .MovieList {
        background: none;
    }

    .MovieListItem {
        width: 100%;
        display: flex;
        color: #2c2c2c;
        margin-bottom: 50px;
    }

    .mobile {
        display: none;
    }

    h1 {
        height: auto;
        background: black;
        color: white;
        margin: 0;
        font-family: oswald;
        font-weight: 600;
        font-size: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 34px;
        cursor: pointer;
    }

    .poster-container {
        width: 20%;
    }

    .movie-info {
        width: 80%;
        display: flex;
        flex-direction: column;
        background-color: #eee;
        min-height: 290px;
        //background-image: linear-gradient(136deg, #f2f2f2 -11%, #ebebeb 48%);
        h1 {
            // height:15%;
        }

        .ShowtimeSchedule2 {
            height: 85%;
        }

       
    }

    .ShowtimeSchedule,
    .schedule {
        flex: 1;
    }

    .ShowtimeSchedule .weekNumberContainer {
        display: none;
    }

    .ShowtimeTypeList {
        //padding: 0 85px 0 65px;
    }

    .movie-info-btn {
        @include button-default;
        @include button-gray;
        position: absolute;
        right: 5px;
        bottom: 6px;
    }
}
.MovieListContainer.week2 .ShowtimeSchedule2 .weekNumberContainer {
    display:none;
}
.showSoon {
    font-weight:600;
    font-size:30px
}
.MovieListItemExtended {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 7.5px;
    justify-content: flex-start;
    margin: 20px 0px;
    font-family: Oswald;

    .showing_soon {
        position: relative;
        height: 469px;
        width: 228px;

        .poster-container {
            height: 351px;
            width: 100%
        }
    }

    .showing_desc {
        background-image: linear-gradient(154deg, #d4d4d4 31%, #eee 131%);
        padding: 0px 12px;
        height: 130px;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0;
        top: 335px;

        .showing_title {
            font-size: 22px;
            font-weight: 500;
            font-family: oswald;
            letter-spacing: 0.12px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 8px 0px 0px 0px;
            // line-height: 3px;
            // height: 0px
        }

        .showing_prem {
            font-size: 17px;
            height: 25px;
            margin: 0;
            padding-top: 5px;
        }

        .showing_btn {
           // background-color: #8c6c49;
            color: white;
            width: 100% ;
            margin-top: 13px;
        }
    }
}

@media (max-width:480px) {
    .showSoon{
        font-size:24px
    }
    .MovieListItemExtended
    .showing_soon {
        width: 167px;
        height: 350px
    }
    .MovieListItemExtended .showing_desc {
        top: 247px;
        height:100px;
        .showing_title {
            font-size:16px
        }
        .showing_prem {
            font-size:13px;
            height:10px
        }
        .showing_btn {
            margin-top:20px
        }
    }
}

    @media (max-width:430px) {
        .MovieListContainer.week2 {
            //.MovieListContainer.week2
            .movie-info-btn {
                top: 64px;
                left: 25%;
                width: 80px;
            }

            .day {
                font-size: 14px;
            }
            //.MovieListContainer.week2
            .movie-info {
                background-color: white;
                min-height: 0;

                h1 {
                    padding-left: 20px;
                    font-size: 18px;
                    height: 48px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
            // .MovieListContainer.week2
            .ShowtimeTypeList {
                //  padding: 10px;
                //background-color: #eee;
                // width: 100vw;
                // background-image: linear-gradient(117deg, #fafafa -19%, #ebebeb 27%);
            }
            // .MovieListContainer.week2
            .ShowtimeSchedule2 .weekNumberContainer {
                display: flex;
                margin-top: 65px;
                //height: 40px;
                //padding-left: 30%;
            }

            .filmPoster {
                //min-width:65px ;
                min-height: 113px;
            }
        }
    }

    @media screen and (min-width:421px) and (max-width:660px) {
        /* .MovieListContainer.week2 .movie-info h1 {
        font-size: 22px;
        
        height: 48px;
       width:100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }*/
    }

    /*@media screen and (max-width:310px)  { 
    .MovieListContainer.week2 .poster-container {
        width:25%
    }
    }*/
    @media screen and (min-width:1270px) {
        .MovieListContainer.week2 .movie-info {
            min-height: 347px
        }
    }

    @media screen and (max-width:870px) {
        .MovieListContainer.week2 h1 {
            font-size: 22px
        }
    }