.TransactionsContainer {
    .Login {
        width: 500px;
        padding: 20px;

        .row {
            margin: 15px 0;

            label, .textbox {
                width: 100%;
                margin-top: 5px;
            }

            .next {
                margin: 0;
            }
        }
    }

    .two-columns .movie-info h1 {
        padding-bottom: 5px;
    }

    .close {
        float: right;
    }

    .removed {
        opacity: 0.5;

        img {
            filter: grayscale(80%);
        }

        &:hover {
            .Poster, h1 {
                box-shadow: none;

                &:hover {
                    pointer-events: none;
                }

                img {
                    pointer-events: none;
                    transition: none;
                    transform: none;
                }
            }
        }

        .read-more {
            color: #ca0000;
            font-weight: bold;
            font-size: 18px;
        }
    }
}

@media (max-width: 450px) {
    .TransactionsContainer {
        .Login {
            width: 100%;

            .row {
                label, .textbox {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}
