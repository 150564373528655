.SeatColors {
     
    display: 'flex';
    text-align: center;
    margin-bottom:30px
   
}

.SeatColors span {
    border: 1px solid gray;
    display: inline-block;
    height: 20px;
    margin-right: 3px;
    margin-left: 20px;
    width: 20px;
    vertical-align: bottom;

   
}
.green {
    background-color: #00cc00;
}

.blue {
    background-color: blue;
}

.white {
    background-color: white;
}

.red {
    background-color: red;
}

@media (max-width:845px) {
    .orderContainer {
        .seatContainer  img {
            max-width:100%
            }
        
    }
}

.noshow {
   height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    color: white;
    font-size: 20px;
    background-color: rgba(161, 161, 161, 0.95);
    opacity: 0;
    //visibility: hidden;

   
    }
.lay-content {
    margin: auto;
    text-align: center;
    padding: 20px;
    margin-top: 150px;
    background-color: rgba(0,0,0,0.6);
    position: relative;
}