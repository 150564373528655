@import '../../../styles/mixins/general-mixins';

.TopBar {
    width: 100%;
    float: left;
    background-color: #2e2e2e;
    overflow: hidden;
    position: relative;

    .Poster {
        width: 21%;
        float: left;
        margin-bottom: -5px;
        @include user-select(none);
        cursor: pointer;

        img {
            width: 100%;
            height: auto;
        }

        .poster-banner img {
            width: 30px;
        }
    }

    @include fullscreen-poster;

    .TicketInfo {
        width: 79%;
        height: 100%;
        float: left;
        color: #bbb;
        padding: 20px;
        padding-left: 40px;
        font-size: 1.1rem;
    }

    h1, h2, h3 {
        color: #e9e9e9;
    }

    h1 {
        min-height: 30px;
        overflow: hidden;
        margin-right: 50px;
        font-weight: normal;
        font-size: 2.2rem;

        &.clickable:hover {
            color: lightgray;
            cursor: pointer;
        }
    }

    .ShowtimeType {
        font-size: 1.2rem;
        position: absolute;
        bottom: 13px;
        color: #e9e9e9;
    }

    .icon-exit {
        position: absolute;
        z-index: 100;
        width: 40px;
        height: 30px;
        right: 5px;
        top: 12px;
        font-size: 24px;
        color: gray;
        text-align: center;
        cursor: pointer;
    }

    label {
        width: 15%;
        float: left;
        display: inline-block;
        font-weight: normal;
    }

    .info {
        width: 100%;
        float: left;
        margin-bottom: 10px;

        .label {
            width: 80%;
            display: inline-block;
        }
    }

    .CountdownTimer {
        position: absolute;
        right: 0;
        bottom: 0;
        flex-direction: column;

        div:first-of-type {
            align-self: flex-end;
        }

        .text {
            font-size: 12px;
            padding-top: 10px;
        }
    }
}

@media (min-width: 1140px) {
    .TopBar {

        h1 {
            font-size: 2.1rem;
        }
    }
}

@media (max-width: 1010px) {
    .TopBar {

        h1 {
            font-size: 1.9rem;
            margin-top: 0;
        }
    }
}

@media (max-width: 880px) {
    .TopBar {

        .Poster {
            width: 28%;
           
        }

        .TicketInfo {
            width: 72%;
            font-size: 1rem;
           

            label {
                width: 23%;
            }

            .info .label {
                width: 77%;
            }
        }
    }
}

@media (max-width: 650px) {
    .TopBar {
        margin-top: 52px;

        .Poster {
            width: 31%;
            margin-top: 52px;
        }

        .fullscreen-img {
            margin-top: 52px;
            width: 100%;
            height: auto;
        }

        .TicketInfo {
            width: 69%;
            padding-top: 5px;
            margin-top: 55px;
        }

        h1 {
            position: absolute;
            top: 0;
            left: 0;
            padding: 7px 20px 10px;
            width: 100%;
            height: 52px;
            //z-index: 15;
            margin: 0;
            white-space: nowrap;
            background-color: #2e2e2e;
            background-image: linear-gradient(to top, #292929, transparent);
        }

        .PriceInfo {
            width: 31%;
        }

        &.club-enabled {
            margin-top: 85px;

            h1 {
               // top: 34px;
            }
        }

        &.header-enabled {
            margin-top: 0;

            h1 {
                text-align: center;
            }
        }
    }
}

@media (max-width: 500px) {
    .TopBar {

        .Poster {
            width: 40%;
        }

        .TicketInfo {
            width: 60%;
            padding-left: 20px;
            font-size: 0.9rem;

            label {
                width: 100%;
            }
        }

        h2 {
            font-size: 1rem;
            position: initial;
            float: left;
        }

        &.club-enabled {
            margin-top: 79px;

            h1 {
                //top: 34px;
            }
        }

        .ShowtimeType {
            position: initial;
        }
    }
}

@media (max-width: 435px ) {
    .TopBar {

        h1 {
            padding: 10px 20px 10px;
            font-size: 1.6rem;
        }

        h3 {
            margin: 5px 0 10px;
        }

        .Poster,
        .TicketInfo {
            width: 50%;
        }

        .duration {
            display: none;
        }
    }
}
