@import './mixins/general-mixins.scss';

* {
    box-sizing: border-box;
}

html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    background-color: white;
    font-size: 15px;
    font-family: sans-serif, 'Trebuchet MS', Verdana;
    line-height: 1.3;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
}

.App {
    width: 100%;
    display: table;
}

.content-wrapper {
    height: 100%;
    margin: auto;
    max-width: 1170px;
}

.page-header {
    h2 {
        font-size: 52px;
        font-weight: 700;
        margin: 0;
    }

    h3 {
        font-size: 30px;
        font-weight: 300;
        margin-top: 20px;
    }
}

.QuantitySelector {
    @include user-select(none);

    .increment,
    .decrement {
        width: 35px;
        height: 33.5px;
        font-size: 35px;
        vertical-align: text-bottom;
        display: inline-flex;
        cursor: pointer;
        border-radius: 50%;

        &:hover {
            background-color: lightgray;
        }
    }

    .deactivated {
        color: gray;
        cursor: default;
        pointer-events: none;

        &:hover {
            background-color: transparent;
        }
    }

    .quantity {
        display: inline;
        vertical-align: sub;
        margin: 0 10px 0 12px;
        font-size: 35px;
    }
}

.OrderFlowContainer {
    background: white;

    .content-container {
        width: 100%;
        display: table;
        padding: 0 20px 20px;
        min-height: 200px;
    }
}

.slide-up {
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-down {
    overflow-y: hidden;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.fade-in {
    visibility: visible;
    opacity: 1;
    transition: all .5s linear;
}

.dim,
.fade-out {
    opacity: 0;
    transition: all .5s ease-out;
    cursor: default;
}

.dim {
    opacity: 0.5;
}

h1 {
    font-size: 1.8rem;
}

h2 {
    font-size: 1.2rem;
}

h3 {
    font-size: 1rem;
}

iframe {
    width: 100%;
    border: none;
}

.no-scroll {
    overflow: hidden;
}

.error {
    color: #bb1212;
}

button,
input[type=button],
input[type=submit] {
    border-radius: 3px;
    min-width: 100px;
    height: 33px;
    padding: 5px 5px 5.5px;
    font-size: 13px;
    font-weight: bold;
    border: none;
    -webkit-appearance: none;
    background-color: buttonface;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input[type=text],
input[type=tel],
input[type=email],
input[type=password] {
    border-radius: 0;
    padding: 5px;
    font-size: 16px;
    font-family: Calibri, sans-serif, Helvetica;
    border: none;
    background-color: #eee;
    border-radius: 0;
}

input[type=checkbox] {
    vertical-align: bottom;
}

input[type=checkbox],
input[type=radio] {
    margin-right: 5px;
}

.nav-buttons {
    width: 100%;
    float: left;
    min-height: 30px;
    background-color: #2e2e2e;
    background-image: linear-gradient(rgba(255,255,255,0),rgba(255,255,255,.05));

    input[type=button],
    button {
        margin-top: 5px;

        &:last-of-type {
            margin-bottom: 5px;
        }
    }

    a {
        width: 100px;
        display: block;
        text-align: center;
        padding: 5px;
        font-weight: bold;
        color: white;
        text-decoration: none;

        span {
            font-size: 16px;
        }
    }

    &.fixed {
        position: sticky;
        bottom: 0;
    }
}

button,
input[type=button],
input[type=submit] {
    @include button-default;

    &.next {
        float: right;
        margin-right: 5px;
        @include button-green;
    }

    &.back {
        float: left;
        margin-left: 5px;
        @include button-gray;
    }
}

footer {
    width: 100%;
    background-color: #2e2e2e;
    color: white;
    text-align: center;
}

@media (max-width: 640px) {
    h3 {
        font-size: 1.1rem;
    }
}

@media (max-width: 424px) {
    .App 
     
    {
        display:block;
        .overlay .overlay-content {
            width: 100%;
        }

        .nav-buttons {
            position: sticky;
            height: 43px;
            bottom: 0;
        }

        .nav-buttons-workaround {
            width: 100%;
            float: left;
            display: block;
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 330px) {
    .App .nav-buttons {
        height: auto;
    }
}
