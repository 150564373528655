@import '../../styles/mixins/general-mixins';

.DatePicker,
.MonthNavigator {
    width: 100%;
    min-height: 50px;
    position: sticky;
    top: 0;
    z-index: 12;
    text-align: center;
    padding: 10px;
    background-color: #181818;
    opacity: 0.9;
    color: white;
    font-size: 20px;
    font-weight: bold;
    @include user-select(none);
}

.DatePicker.show-as-week-buttons {
    display: flex;
    opacity: 1;
    background: none;
    padding: 20px 0 0;

    .WeekDayPicker {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: 56px; // To hide the extra day buttons on smaller screens
        overflow: hidden; //
    }

    .day {
        padding: 10px 10px 0 10px;
        color: #a0a0a0;
        font-size: 19px;
        padding: 5px 20px;
        border: solid 1px #4c4c4c;
        margin: 0 15px 20px 0;
        white-space: nowrap;
        border-radius: 2px;

        &.selected {
            background-color: #2c2c2c;
            color: white;
        }

        &:hover {
            color: lightgray;
            cursor: pointer;
        }
    }

    .more-dates {
        cursor: pointer;

        .day {
            color: white;
        }

        &:hover {
            color: #d3d3d3;
        }
    }

    .more-dates, .WeekDayPicker {
        width: auto;
        font-weight: normal;

        .day.selected {
            background-color: #f7f7f7;
            color: #323232;
        }
    }

    .LanguagePicker {
        position: absolute;
        right: 0;
    }

    .Calendar {
        top: 76px;
    }

    .icon-calendar {
        margin-right: 8px;
    }
}

.MonthNavigator {
    z-index: 11;
}

.icons-placeholder {
    float: left;
    width: 50px;
    height: 30px;
}

.DayNavigator,
.MonthNavigator {
    display: inline-block;
    font-size: 21px;

    .date {
        width: 320px;
        cursor: pointer;
        display: inline-block;
        margin: 0 10px;

        .short-day,
        .short-month {
            display: none;
        }

        .date-number,
        .month,
        .short-month,
        .year {
            margin-left: 10px;
        }
    }

    .icon-calendar {
        margin-right: 10px;
    }

    .date-nav {
        width: 50px;
        display: inline-block;
        cursor: pointer;

        &.hidden {
            visibility: hidden;
        }

        &.disabled {
            color: #666;
        }
    }
}

.MonthNavigator .date {
    cursor: default;
}

.Calendar {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: inherit;
    max-width: inherit;
    background-color: black;
    padding-bottom: 10px;
    z-index: 30;

    .icon-close {
        position: absolute;
        right: 0;
        color: white;
        padding: 10px;
        cursor: pointer;
        z-index: 1;

        &:hover {
            color: lightgrey;
        }
    }

    .calendar-text {
        font-size: 1rem;
        padding: 0 20px;
    }

    &.slide-down {
        min-height: 345px;
    }
}

@media (max-width: 625px) {
    .MonthNavigator,
    .DayNavigator {
        .date {
            width: 260px;
        }

        .year {
            display: none;
        }
    }
}

@media (max-width: 520px) {
    .MonthNavigator,
    .DayNavigator {
        .date {
            width: 200px;

            .short-month {
                display: inline-block;
            }

            .month {
                display: none;
            }
        }
    }
}

@media (max-width: 420px) {
    .MonthNavigator,
    .DayNavigator {
        .date {
            width: 165px;
            font-size: 19px;
            margin: 0;

            .short-day {
                display: inline-block;
            }

            .day {
                display: none;
            }
        }
    }

    .Calendar {
        font-size: 16px;
    }
}

@media (max-width: 350px) {
    .MonthNavigator,
    .DayNavigator {
        .date {
            width: 130px;

            .short-day {
                margin-left: 0;
            }
        }
    }

    .icon-calendar {
        display: none;
    }
}

@media (max-width: 310px) {
    .MonthNavigator,
    .DayNavigator {
        .date {
            font-size: 18px;
            width: 85px;

            .date-number {
                margin-left: 0;
            }

            .short-day {
                display: none;
            }
        }
    }

    .Calendar {
        font-size: 14px;
    }

    .DayPicker {
        padding: 20px 0 0;
    }
}

@media (max-width: 245px) {
    .DatePicker {
        min-height: 106px;
        padding: 5px;
    }

    .MonthNavigator,
    .DayNavigator {
        .date-nav,
        .date-nav:first-of-type {
            width: auto;
            height: 35px;
            display: block;
            margin: auto;
        }
    }
}
