.GiftCardContainer {

    .content-container {
        max-width: 640px;
    }

    .overview {
        width: 93%;
        max-width: 560px;
    }

    p {
        margin: 10px 0;
    }

    span {
        float: right;
    }

    .active .clear {
        float: none;
    }

    .VoucherInput {
        margin-top: 35px;
    }
}
