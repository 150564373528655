.BioKlubDkContainer {
    p {
        margin: 10px 0;
    }

    .active,
    .errors,
    .clear {
        margin-top: 5px;
    }

    img {
        width: 100%;
        max-width: 500px;
        margin: 5px 0;
    }

    .icon-tool-tip {
        font-size: 21px;
        padding: 5px;
        vertical-align: bottom;
        cursor: pointer;
    }
}
