.CommercialsContainer {
    padding-bottom: 43px;

    .CommercialListItem {
        width: 100%;
        position: relative;
        color: white;
        transition: all .2s ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.04);
            box-shadow: inset 0 0 1em black, 0 0 1em black;
        }

        .img-container {
            overflow: hidden;
            height: 450px;
        }

        img {
            width: 100%;
            height: auto;
        }

        .text {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
        }
    }

    .nav-buttons {
        height: 43px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        .next {
            width: 99.5%;
            margin: 0 5px;
            float: none;
            font-size: 20px;
        }
    }
}

@media (max-width: 765px) {
    .CommercialsContainer .CommercialListItem .img-container {
        height: 350px;
    }
}

@media (max-width: 645px) {
    .CommercialsContainer .CommercialListItem .img-container {
        height: 250px;
    }
}

@media (max-width: 415px) {    
    .CommercialsContainer .CommercialListItem .img-container {
        height: 200px;
    }
}
