.OrderContainer {
    .table-container {
        width: 100%;
        display: table;
    }

    .AvailableTickets {
        width: 21%;
        display: table-cell;
        vertical-align: top;
        padding: 0 10px;
        background-color: #eee;

        .location-text {
            padding: 20px 10px 0;
        }

        .Ticket {
            padding-top: 20px;
            text-align: center;

            &:last-of-type {
                margin-bottom: 10px;
            }

            h3 {
                margin: 0;
            }

            .price {
                color: #666;
            }
        }

        .discount {
            margin: 45px 0 20px;

            .VoucherInput {
                display: flex;
                margin-bottom: 10px;
            }

            .VoucherInput {
                margin-bottom: 5px;
            }

            input[type=text] {
                width: 75%;
                background: white;
            }

            .send {
                margin-left: 2%;
            }

            span {
                font-size: 0.8rem;
            }
        }
    }

    .SeatSelection {
        display: table-cell;
        padding: 0 20px 20px;
        text-align: center;

        h1 {
            margin-bottom: 10px;
        }

        .seat-container {
            padding-top: 20px;
        }

        img {
            min-height: 380px;
            margin-bottom: 20px;
            cursor: pointer;
        }

        img.dim {
            filter: grayscale(100%);
            cursor: default;
        }

        .unnumbered {
            min-height: 300px;
            padding-top: 100px;
        }

        .error {
            font-size: 17px;
            margin: 20px 0;
        }

        .SelectedSeats {
            font-weight: bold;
            padding-bottom: 20px;
        }

        .seatModal-container_btn {
            text-decoration: underline;
            font-size: 14px;
            font-family: LibreFranklin;
            color: #000;
            letter-spacing: 0.14px
        }

        .SeatColors {
            span {
                width: 20px;
                height: 20px;
                display: inline-block;
                vertical-align: bottom;
                border: 1px solid gray;
                margin-left: 20px;
                margin-right: 0px;

                &:first-of-type {
                    margin-left: 0;
                }
            }
        }

        .green {
            background-color: #00cc00;
        }

        .blue {
            background-color: blue;
        }

        .white {
            background-color: white;
        }

        .red {
            background-color: red;
        }
    }

    .nav-buttons {
        .back-buttons {
            width: 35%;
            float: left;
        }

        .next-buttons {
            width: 65%;
            float: right;
        }

        .buy-and-reserve-disabled {
            display: inline-block;
            color: #fff;
            margin-top: 3px;
            float: right;
            padding-right: 2%;
        }
    }
}

@media (max-width: 845px) {
    .OrderContainer {
        .AvailableTickets {
            width: 28%;
        }

        .SeatSelection img {
            max-width: 100%;
        }
    }
}

@media (max-width: 650px) {
    .OrderContainer {
        .AvailableTickets {
            width: 31%;
        }

        .SeatSelection img {
            min-height: unset;
        }
    }
}

@media (max-width: 530px) {
    .OrderContainer {
        .AvailableTickets,
        .SeatSelection,
        .discount {
            width: 100%;
            float: left;
        }


        .AvailableTickets {
            .Ticket {
                width: 43%;
                float: left;
                margin: 0 10px 10px;
            }

            .discount {
                margin: 5px 10px 20px;

                .VoucherInput {
                    margin-top: 0;
                }
            }
        }

        .SeatSelection img {
            margin: 0 0 10px;
        }
    }
}

@media (max-width: 424px) {
    .OrderContainer {

        .SeatSelection {
            img {
                min-height: unset;
            }

            .unnumbered {
                min-height: 200px;
                padding-top: 50px;
            }
        }

        .SeatColors {
            font-size: 12px;
        }

        .nav-buttons {
            display: flex;
            height: auto;

            .back-buttons,
            .next-buttons {
                width: 100%;
            }

            &.window {
                height: 43px;

                .next-buttons {
                    width: 100%;
                    height: auto;

                    .next {
                        position: static;
                    }
                }
            }
        }
    }
}
