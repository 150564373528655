.ExternalEvent {
    .top {
        width: 100%;
        float: left;
        background-color: #eee;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4));

        .Poster {
            width: 100%;
            float: left;
            margin-bottom: -6px;
            margin-right: 0;
        }

        h1 {
            margin-top: 0;
        }

        .cancelled {
            color: #cc0000;
        }

        h3, h4 {
            margin: 5px 0;
        }

        .info {
            width: 75%;
            float: left;
            padding: 20px;
        }
    }

    .description {
        width: 100%;
        float: left;
        padding: 20px;
        margin: 0;
        box-shadow: inset 0 3px 13px -7px #666, inset 0 -8px 9px -9px #666;
        background: white;

        p {
            margin-top: 0;
        }
    }
}

.ExternalList {
    .MovieList {
        display: flex;
        flex-wrap: wrap;
    }

    .MovieListItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(166deg, lightgray 0%, #eee 75%);

        .show-info {
            width: 100%;
            margin-top: auto;
            display: flex;
            flex-direction: column;
        }
    }

    .Showtime {
        align-self: center;
    }
}
