@import '../../styles/mixins/general-mixins';

.DayPicker {
    width: 100%;
    max-width: 450px;
    margin: auto;
    position: relative;
    padding: 20px 20px 10px;
    background-color: black;
    @include user-select(none);

    abbr {
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    .DayPicker-NavBar {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 .5rem;

        .DayPicker-NavButton {
            position: absolute;
            width: 50px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
        }

        .DayPicker-NavButton--interactionDisabled {
            display: none;
        }

        .DayPicker-NavButton--prev {
            left: 2.5rem;

            &:before {
                content: '\00276E';
            }
        }

        .DayPicker-NavButton--next {
            right: 2.5rem;

            &:before {
                content: '\00276F';
            }
        }
    }

    .DayPicker-Month {
        width: 100%;
        display: table;
        border-collapse: collapse;
        border-spacing: 0;
        @include user-select(none);

        .DayPicker-Caption {
            display: table-caption;
            text-align: center;
        }

        .DayPicker-Weekdays {
            display: table-header-group;
        }

        .DayPicker-WeekdaysRow {
            display: table-row;
        }

        .DayPicker-Weekday {
            display: table-cell;
            padding: .5rem;
            font-size: .875em;
            text-align: center;
            color: #b7b7b7;
        }

        .DayPicker-Body {
            display: table-row-group;
        }

        .DayPicker-Week {
            display: table-row;
        }

        .DayPicker-Day {
            width: 50px;
            display: table-cell;
            padding: .5rem;
            border: 1px solid #eaecec;
            text-align: center;
            cursor: pointer;
            vertical-align: middle;

            &:hover {
                background: rgba(0, 255, 0, 0.2);
            }

            &:focus {
                outline: none;
            }
        }

        .DayPicker--interactionDisabled .DayPicker-Day {
            cursor: default;
        }

        .DayPicker-Day--disabled {
            color: #5e5e5e;
            cursor: default;

            &:hover {
                border: 1px solid #eaecec;
                background: rgba(255, 0, 0, 0.2);
            }
        }

        .DayPicker-Day--outside {
            cursor: default;
            color: transparent;

            &:hover {
                background: none;
            }
        }

        .DayPicker-Day--sunday {
            background-color: #f7f8f8;
        }

        .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
            color: #dce0e0;
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            color: white;
            background-color: green;
        }
    }
}
